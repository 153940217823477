import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';
import { Mypage as MypageContent } from '@features/mypage';
import { History } from '@features/mypage/History';

const MyPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>マイページTOP | {title}</title>
        {Description}
        {NoIndex}
      </Helmet>
      <Suspense fallback={<Loading />}>
        <MypageContent>
          <History />
        </MypageContent>
      </Suspense>
    </Layout>
  );
};

export default MyPage;
